<template>
  <div class="homepage">
    <div class="homepage-top">
      <div class="top-left">
        <div>
          <p>“{{ $t("home.titleOne") }}</p>
          <p>{{ $t("home.titleTwo") }}</p>
          <p>{{ $t("home.titleThree") }}”</p>
        </div>
        <el-button @click="toEmdata" type="primary" style="margin-left:200px"
          >{{ $t("home.inlet") }} emData</el-button
        >
      </div>
      <div class="top-right">
        <div v-html="pageInfo.homePageMainContent" class="carousel">
          <!-- {{}} -->
          <!-- <slide-show :data="dataList"></slide-show> -->
        </div>
      </div>
    </div>
    <div class="homepage-middle">
      <ul class="middle-container">
        <li>
          <!-- <p>{{systemInfo.companyNum}}</p> -->
          <p>
            <countTo
              :startVal="startVal"
              :endVal="systemInfo.companyNum"
              :duration="duration"
            ></countTo>
          </p>
          <p>{{ $t("home.companyUsers") }}</p>
        </li>
        <li>
          <!-- <p>{{systemInfo.personNum}}</p> -->
          <p>
            <countTo
              :startVal="startVal"
              :endVal="systemInfo.personNum"
              :duration="duration"
            ></countTo>
          </p>
          <p>{{ $t("home.individualUsers") }}</p>
        </li>
        <li>
          <!-- <p>{{systemInfo.projectNum}}</p> -->
          <p>
            <countTo
              :startVal="startVal"
              :endVal="systemInfo.projectNum"
              :duration="duration"
            ></countTo>
          </p>
          <p>{{ $t("home.item") }}</p>
        </li>
        <li>
          <!-- <p>{{systemInfo.formHandleNum}}</p> -->
          <p>
            <countTo
              :startVal="startVal"
              :endVal="systemInfo.formHandleNum"
              :duration="duration"
            ></countTo>
          </p>
          <p>{{ $t("home.form") }}</p>
        </li>
      </ul>
      <!-- <p class="title">在线管理您的<span>建筑数据!</span></p>
			<div class="text">
				<p>联系：emdata2021@163.com</p>
				<p >
					emData是管理BIM建筑数据必不可少的衍生工具，我们将为您提供强大Revit底层数据处理工具和数据可视化平台，让您畅通无阻的在线获取、浏览、管理、分析您的资产数据
				</p>
				<p></p>
			</div> -->
    </div>
    <div class="homepage-bottom">
      <div class="container-box">
        <p>
          <!-- <span class="title-bottom">联系</span> -->
          <span class="rightM"
            >{{ $t("home.email") }} {{ pageInfo.email }}</span
          >
          <span>{{ $t("home.mob") }} {{ pageInfo.phone }}</span>
        </p>
        <p>
          <a class="rightM">鹦鹉塔科技</a
          ><a href="https://beian.miit.gov.cn">沪ICP备2021021283号-1</a>
        </p>
      </div>
      <a
        href="http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020210907092316000008223021-SAIC_SHOW_310000-2021112417183919342&signData=MEQCIEvCF6dT5AvJ6vkJYHPEK8LjFhovh5mnuV3mBejHz/b2AiBUc/snSGBjK4CV15aSZUpemWt/zBbbF4nsvpX/0TXsJw=="
        style="margin-left:10px;"
        ><img src="../../assets/officialwebsite/dz.png" width="30" height="30"
      /></a>
    </div>
    <div class="erweima">
      <img
        class="erweimg"
        src="../../assets/officialwebsite/erweima.png"
        alt=""
      />
      <span style="font-size: 12px; color: #fff; line-height:30px">{{
        $t("home.kf")
      }}</span>
    </div>

    <div class="erweima" style="right: 11%; ">
      <img
        class="erweimg"
        src="../../assets/emData.jpg"
        style="align-self: center;"
      />
      <span
        style="font-size: 12px; color: #fff; line-height:30px;align-self: center;"
        >{{ $t("home.gzh") }}</span
      >
    </div>
  
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  metaInfo: {
    title: "emData 首页", // set a title
    meta: [
      {
        // set meta
        name: "emData",
        content:
          "emData是管理BIM建筑数据必不可少的衍生工具，我们将为您提供强大Revit底层数据处理工具和数据可视化平台，让您畅通无阻的在线获取、浏览、管理、分析您的资产数据",
      },
      {
        name: "鹦鹉塔科技",
        content: "消除技术门槛，整合建筑信息，人人皆可使用并从中受益",
      },
    ],
    link: [
      {
        rel: "emData 首页",
        href: "https://www.emdataer.com",
      },
    ],
  },
  name: "index",
  data() {
    return {
   
      pageInfo: JSON.parse(localStorage.getItem("pageInfo")),
      systemInfo: JSON.parse(localStorage.getItem("systemInfo")),
      // 需要滚动的时间
      duration: 1000,
      // 初始值
      startVal: 0,
      // 最终值
      // endVal: 2018
    };
  },
  components: { countTo },
  beforeCreate() {
    localStorage.setItem("pageInfo", JSON.stringify({}));
    localStorage.setItem(
      "systemInfo",
      JSON.stringify({
        companyNum: 0,
        personNum: 0,
        projectNum: 0,
        formHandleNum: 0,
      })
    );
  },
  created() {
    this.init();
  },
  methods: {
	
    toEmdata() {
      this.$router.push("/space");
    },
  
    init() {
      this.$axios.get(`/api/homepage/gethomepageinfo`).then((res) => {
        // this.pageInfo = res
        console.log(res);
        if (!res.isLogin) {
          // 清除缓存
          var userName = localStorage.getItem("userName");
          var password = localStorage.getItem("passWord");
          var currSysVersion = localStorage.getItem("currSysVersion");

          localStorage.clear();
          // this.$router.push("/login");
          localStorage.setItem("userName", userName);
          localStorage.setItem("passWord", password);
          localStorage.setItem("currSysVersion", currSysVersion);
        }
        if (res != JSON.parse(localStorage.getItem("pageInfo"))) {
          this.pageInfo = res;
          localStorage.setItem("pageInfo", JSON.stringify(res));
        }
      });
      this.$axios.get("/api/systemother/getprojectandothernum").then((res) => {
        // this.systemInfo = res
        if (res != JSON.parse(localStorage.getItem("systemInfo"))) {
          this.systemInfo = res;
          localStorage.setItem("systemInfo", JSON.stringify(res));
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>

.homepage {
  position: relative;
  width: 100%;
  // height: calc(100vh - 60px);
  height: 909px; //加上标签高度32
  background-image: url(../../assets/officialwebsite/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .erweima {
    position: absolute;
    right: 4.3%;
    bottom: 70px;
    display: flex;
    flex-direction: column;
    .erweimg {
      width: 90px;
      height: 90px;
    }
  }
  .rightM {
    margin-right: 20px;
  }
  p {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .homepage-top {
    display: flex;
    justify-content: space-between;
    height: 60%;

    .top-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 50%;
      box-sizing: border-box;
      p {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #475065;
        // margin-top: 20px;
      }
      p:nth-child(1) {
        margin-left: -30px;
      }
      p:nth-child(2),
      p:nth-child(3) {
        margin-top: 20px;
      }
      .el-button {
        margin-top: 6%;
      }
      .el-button--primary {
        width: 204px;
        background-color: #475065;
        border-color: #475065;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        padding: 22px 20px;
      }
    }
    .top-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 41.5%;
      box-sizing: border-box;
      // border: 1px solid #000000;
      .carousel {
        width: 610px;
        font-size: 32px;
        line-height: 50px;
        overflow: hidden;
        border-color: #475065;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        // background-color: #000088;
      }
    }
  }
  .homepage-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 34%;
    padding-top: 50px;
    box-sizing: border-box;
    width: 90%;
    .middle-container {
      display: flex;
      justify-content: space-between;
      width: 70%;
      font-size: 22px;
      color: #fff;
      p {
        text-align: center;
      }
      p:nth-child(1) {
        font-size: 64px;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-style: italic;
      }
    }
  }
  .homepage-bottom {
    display: flex;
    align-items: center;
    margin-left: 2.8%;
    height: 6%;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    box-sizing: border-box;
    // padding: 0 300px;
    .container-box {
      display: flex;
      justify-content: space-between;
      width: 93.4%;
      // background-color: #000000;
      // text-align: center;
    }
    .title-bottom {
      // margin-right: 20px;
    }
    a {
      text-decoration: none;
    }
    a:link {
      color: #ffffff;
    } /* 未被访问的链接 */
    a:visited {
      color: #ffffff;
    } /* 已被访问的链接 */
    a:hover {
      color: #ffffff;
    } /* 鼠标指针移动到链接上 */
    a:active {
      color: #ffffff;
    } /* 正在被点击的链接 */
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
